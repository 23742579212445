* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  background: black;
  overflow-x: hidden;
  font-size: 16px;
}

*:focus,
*:active,
*:focus-visible * {
  outline: none !important;
}

a,
button {
  all: unset;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

a,
h1,
img,
svg {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

:root {
  --pink: #f3499b;
  --neon-pink: #EA50E9;
  --yellow: #FAFF00;
  --lightBlue: #56b1b7;
  --gradBtn: linear-gradient(137deg, #ea50e9 0%, #ffe600 100%);
  --footerGrad: linear-gradient(
    117deg,
    #ea50e9 0%,
    #e561e9 31.96%,
    #f183be 59.81%,
    #f479ba 78.6%,
    #f37cc3 100%
  );
  --eventGrad: linear-gradient(95.42deg, #F3499B 2.54%, #EA50E9 39.84%, #FAFF00 118.78%);
  --titles: 1.69rem;
  --fweight: 600;
  --btnFontSize: 1.125rem;
  --lineh: 1.2;
}

body {
  background-color: black !important;
  font-family: "Instrument Sans" !important;
  overflow: hidden;
}

.mob {
  display: none;
}

h3 {
  font-size: var(--titles) !important;
  color: white !important;
  line-height: var(--lineh);
  span {
    background: var(--footerGrad);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;

  img {
    max-width: 50px;
  }

  .menu-btn-cnt {
    position: relative;
    display: flex;
    justify-content: flex-end;

    a,
    button {
      color: white;
      text-decoration: none;
      font-size: var(--btnFontSize);
      font-weight: 600;
      min-width: fit-content;
      padding: 1rem 1.875rem;
      border-radius: 9999px !important;
      transition: 0.3s ease-in-out;
      background: black;
      cursor: pointer;
    }
    #esc-btn {
      background: #00000047;
      border-radius: 50% !important;
      padding: 1rem;
      height: 1.75rem;
      width: 1.75rem;
      text-align: center;
      position: relative;
      z-index: 3;
    }

    #join-btn {
      background: #000000;
      /* position: absolute; */
      z-index: 4;
      /* right: 1rem; */
    }
    #about-btn {
      background: #3a1b59;
      position: absolute;
      z-index: 5;
      right: 2.5rem;
    }

    #discover-btn {
      height: 1.75rem;
      position: absolute;
      z-index: 6;
      right: 4rem;
      cursor: pointer;
    }

    .menu.open {
      gap: 1.5rem;
    }
  }
}
.btn-p{
  color: white;
  text-decoration: none;
  font-size: var(--btnFontSize);
  font-weight: 600;
  min-width: fit-content;
  padding: 1rem 1.5rem;
  border-radius: 9999px !important;
  transition: 0.3s ease-in-out;
  background: black;
  cursor: pointer;
  text-transform: uppercase;
}

.home-cnt {
  position: relative;
  /* background: var(--pink); */
  background: linear-gradient(to bottom, var(--pink) 99%, rgba(0, 0, 0, 0) 1%);
}

.home {
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ethna {
    z-index: 5;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      height: auto;
      max-width: 600px;
      width: 100%;
    }

    h3 {
      max-inline-size: 25ch;
      font-weight: var(--fweight);
      margin: 4rem auto;
    }
  }
}

.what {
  color: white;
  font-size: var(--titles);
  line-height: 98%;
  padding: 0 10rem;
  align-items: center;
  position: relative;
  z-index: 99;
  margin: 0;
  margin-top: -1px;

  img {
    width: 100%;
    height: auto;
  }

  h3 {
    /* max-inline-size: 25ch; */
    max-inline-size: 27ch;
    font-weight: var(--fweight);
  }
}

.community {
  margin-top: 15%;
  color: white;

  h3 {
    /* max-inline-size: 30ch; */
    max-inline-size: 37ch;
    margin: auto;
    opacity: 0;
    font-weight: var(--fweight);
  }

  .category {
    padding: 0 1rem;
  }

  .community-logos-cnt {
    margin-top: 15%;
    margin-bottom: 15%;
    .col {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      min-height: 12rem;
      text-align: center;
      padding: 1rem 0.5rem;
      line-height: 1.2;

      &:hover img {
        transform: translateY(-20px);
      }

      img {
        height: 114px;
        width: 88px;
        transition: 0.3s ease-in-out;
      }
    }

    p {
      font-size: 1.5625rem;
      font-weight: 700;
      margin-top: 2rem;
    }
  }
}

.friends {
  margin-top: 15%;
  margin-bottom: 5%;
  overflow-x: hidden;
  max-width: 100vw;

  .banner-loghi {
    display: flex;
    align-items: center;
    justify-content: space-around;

    a {
      cursor: pointer;
    }

    img {
      height: auto;
      width: 203px;
      max-height: 150px;
      margin: 0 3rem;
    }
  }

  .banner {
    display: flex;
    margin-bottom: 6rem;

    h1 {
      font-size: 4.375rem;
      font-weight: 600;
      color: white;
      white-space: nowrap;
      word-break: keep-all;

      span {
        margin: 0 2rem;
      }
    }
  }
}

footer {
  position: relative;
  background: var(--footerGrad);
  padding-bottom: 1rem;
  color: black;

  .mob {
    display: none;
  }
  .desk {
    display: inline;
  }

  img {
    margin-top: -2rem;
    max-width: 100%;
  }

  svg {
    max-width: 40px;
    height: auto;
  }

  .lava {
    margin-top: -3px;
    width: 100%;
    height: auto;
    margin-bottom: 10%;
  }

  .row {
    padding: 0 5rem;

    &:nth-last-child(1) {
      padding-top: 4rem;
    }
  }

  p {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.96875rem;
    margin-bottom: 0.5rem;
  }

  .join {
    font-size: 1.875rem;
    font-weight: 600;
  }

  .txt-b {
    font-family: Instrument Sans;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.96875rem;
    text-transform: uppercase;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  .social-cnt {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .social {
    a {
      /* margin-right: 1.5rem; */
      background: black;
      border-radius: 50%;
      height: 100%;
      width: 100%;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 1.5rem;
      max-height: 1.5rem;

      svg {
        max-width: 30px;
      }

      svg path {
        transition: 0.2s ease-in-out;
        fill: white;
      }

      &:hover svg path {
        fill: white;
        fill: var(--pink);
      }
    }
  }
}

.grad-btn-cnt {
  width: fit-content;
  position: relative;
  cursor: pointer;

  &:hover {
    .btn-1,
    .btn-2 {
      right: 0;
    }
    .btn-4,
    .btn-5 {
      left: 0;
    }
  }

  .grad-btn {
    background: black;
    width: 100%;
    min-width: fit-content;
    height: 4rem;
    padding: 1rem 1.875rem;
    border-radius: 9999px !important;
    transition: 0.3s ease-in-out;
    text-transform: uppercase;
  }

  p {
    color: white;
    font-size: var(--btnFontSize);
    font-weight: 600;
    cursor: pointer;
  }

  .btn-1,
  .btn-2,
  .btn-4,
  .btn-5 {
    position: absolute;
  }

  .btn-1 {
    opacity: 40%;
    z-index: 2;
    right: -5rem;
  }
  .btn-2 {
    opacity: 50%;
    z-index: 3;
    right: -3rem;
  }
  .btn-3 {
    position: relative;
    z-index: 4;
  }
  .btn-4 {
    left: -3rem;
    opacity: 50%;
    z-index: 3;
  }
  .btn-5 {
    opacity: 40%;
    z-index: 2;
    left: -5rem;
  }
}

.volcano-cnt {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  height: 100lvh;
  width: 100vw;

  .bg {
    width: 100%;

    mix-blend-mode: hard-light;
    opacity: 90%;

    .bg-image {
      width: 100%;
      max-height: 100vh;
      object-fit: cover;
    }
  }

  .volcano {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30rem;
    width: 100%;
    z-index: 0;
  }
}

.cta {
  text-align: center;
  padding: 4rem 0;
  p {
    font-size: var(--titles);
    font-weight: var(--fweight);
    line-height: var(--lineh);
    color: white;
    margin: auto;
    max-inline-size: 47ch;

    span {
      background: var(--footerGrad);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .grad-btn-cnt {
    margin: 0 auto;
    margin-top: 3rem;

    p {
      font-size: var(--btnFontSize);
      line-height: 1.9;
      font-weight: 700;
      color: black;
    }

    &:hover {
      .btn-4 {
        left: 0;
      }
      .btn-5 {
        left: 0;
      }
    }

    a {
      text-decoration: none;
    }
    .grad-btn {
      background: var(--gradBtn);
    }
  }
}

.category {
  color: #5c5b5c;
  font-size: 0.6875rem !important;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.0625rem;
  font-family: "Stem";
  display: none;
}

.loader {
  background: black;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999999;
  animation: fadeOut 3s ease-in-out;
  overflow-y: hidden;

  svg {
    max-width: 100px;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

// Event Page
.event-header {
  .menu-btn-cnt {
    a,button {
      height: 4rem;
      box-sizing: border-box;
    }
  }
}

.event-s1 {
  position: relative;
  /* background: var(--pink); */
  background: var(--eventGrad);

  .event {
    width: 100vw;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ethna {
      z-index: 5;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        height: auto;
        max-width: 600px;
        width: 100%;
      }

      h3 {
        max-inline-size: 25ch;
        font-weight: var(--fweight);
        font-family: Stem;
        margin: 4rem auto;
      }

      .event-cta {
        text-align: center;
        padding: 4rem 0;
        // margin-top: 8rem;

        & > .row {
          margin-right: auto;
          margin-left: auto;
        }

        .apply-as-text {
          font-size: 1.2rem;
          margin-bottom: 1rem;
          font-weight: normal;
        }

        p {
          font-size: var(--titles);
          font-weight: var(--fweight);
          line-height: var(--lineh);
          color: white;
          margin: auto;
          max-inline-size: 47ch;

          span {
            background: var(--footerGrad);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .grad-btn-cnt {
          margin-top: 3rem;

          p {
            font-size: var(--btnFontSize);
            line-height: 1.9;
            font-weight: var(--fweight);
            color: black;
          }

          &:hover {
            .btn-4 {
              left: 0;
            }
            .btn-5 {
              left: 0;
            }
          }

          a {
            text-decoration: none;
          }
          .grad-btn {
            background: var(--gradBtn);
          }
        }

        .grad-btn-cnt:first-child {
          margin-right: 200px;
        }
      }

      .apply-ctn {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

}

.event-s2 {
  &.what {
    img {
      max-width: 500px;
    }
    h3 {
      font-weight: normal;
      line-height: 1.3;
    }
  }
}

.event-s3 {
  margin-top: 15%;

  h1 {
    color: white;
    font-weight: 500;
    font-size: 100px;
    text-align: center;
    line-height: 0.9;
  }

  .event-s3-description {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    color: white;
    margin: 4rem auto;
    font-size: var(--titles);
    font-weight: normal;
    line-height: 1.3;
    max-inline-size: 47ch;
  }

  .grad-btn-cnt {
    margin: 0 auto;
    margin-top: 3rem;

    p {
      font-size: var(--btnFontSize);
      line-height: 1.9;
      font-weight: var(--fweight);
      color: black;
    }

    &:hover {
      .btn-4 {
        left: 0;
      }
      .btn-5 {
        left: 0;
      }
    }

    a {
      text-decoration: none;
    }
    .grad-btn {
      background: var(--gradBtn);
    }
  }
}

.event-s4 {
margin-top: 8rem;

  .event-s4-row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .event-s4-col {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > img {
        height: 70px;
      }

      & > p {
        color: white;
        text-align: center;
        font-size: var(--titles);
        font-weight: var(--fweight);
        line-height: var(--lineh);
      }

      .event-s4-chip {
        margin-top: 2.5rem;
        margin-bottom: 2rem;
      }
    }
  }
}

.event-s5 {
margin-top: 8rem;
  .banner {

    h1 {
      font-size: 4.375rem;
      font-weight: var(--fweight);
      color: white;
    }

    img {
        margin: 0 2rem;
    }
  }

  .speakers-ctn {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    margin-top: 5rem;
    justify-items: center;
    justify-content: center;
    & > div {
      width: 100%;
      padding: 2rem;
      max-width: 300px;
    }
  }
}
.event-s6 {
  margin-top: 8rem;
  .banner {
    h1 {
      font-size: 4.375rem;
      font-weight: var(--fweight);
      color: white;
    }

    img {
        margin: 0 2rem;
    }
  }

  .sponsors-ctn {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    margin-top: 5rem;
    justify-items: center;
    justify-content: center;
    & > div {
      max-width: min(100%, 300px);
      width: 100%;
      display: flex;
      justify-content: center;

      a {
        display: inline-block;
      }

      img {
        max-width: 100%;
        height: 300px;
        margin: auto;
        object-fit: contain;
      }
    }
  }
}

.event-s7 {
  margin-top: 8rem;
  padding: 0 4rem;

  .event-s7-schedule-tab-ctn {
    margin-bottom: 4rem;

    .event-s7-chip {
      display: block;
      font-weight: 500;
    }
  }

  .event-s7-schedule-cards-ctn {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 4rem;

    & .schedule-card {
      width: 32.5%;
      margin-bottom: 30px;
    }
  }

  .event-s7-ethna-off-ctn {
    width: 100%;
    position: relative;
    & > img {
      width: 100%;
    }

    .event-s7-ethna-off-info {
      position: absolute;
      top: 0;
      width: 100%;
      height: 57%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .my-chip {
        width: 300px;
      }

      .event-s7-ethna-off-desc {
        margin-top: 10px;
        font-size: 1.5rem;
        color: black;
        font-weight: var(--fweight);
        margin-bottom: 0;
      }
    }
  }

  &.mobile {
    padding: 0 2rem;
    .event-s7-chips-ctn {
      display: flex;
      justify-content: space-between;

      .event-s7-chip {
        width: 48%;
      }
    }

    .event-s7-schedule-tab-ctn {
      margin-bottom: 2rem;

      .event-s7-schedule-cards-ctn {
        margin-top: 2rem;
        flex-direction: column;

        & .schedule-card {
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 2rem;
          }
        }
      }
    }

    .event-s7-ethna-off-ctn {
      .event-s7-ethna-off-info {
        position: absolute;
        top: 0;
        height: 62%;
        text-align: center;

        .my-chip {
          width: unset;
        }

        .event-s7-ethna-off-desc {
          padding-right: 10px;
          padding-left: 10px;
        }
      }
    }
  }
}

.event-s8 {
  margin-top: 8rem;
  margin-bottom: 10rem;
  & > h1 {
    color: white;
    font-weight: 500;
    font-size: 100px;
    text-align: center;
  }

  .event-s8-info-ctn {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10rem;
    & > div {
      width: 20%;
      max-width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      & > img {
        height: 70px;
        margin-bottom: 1rem;
      }
      & > p {
        color: white;
        text-align: center;
        font-weight: normal;
        line-height: var(--lineh);
      }
    }

  }

  .grad-btn-cnt {
    margin: 0 auto;
    margin-top: 5rem !important;

    p {
      font-size: var(--btnFontSize);
      line-height: 1.9;
      font-weight: var(--fweight);
      color: black;
    }

    &:hover {
      .btn-4 {
        left: 0;
      }
      .btn-5 {
        left: 0;
      }
    }

    a {
      text-decoration: none;
    }
    .grad-btn {
      background: var(--gradBtn);
    }
  }
}

.event-footer {
  background: var(--eventGrad);

  .event-footer-phrase {
    font-size: var(--titles);
    font-weight: var(--fweight);
    margin: 4rem auto;
  }

  .event-footer-lava {
    margin-top: -3px;
    width: 100%;
    height: auto;
    margin-bottom: 10%;
    max-width: calc(100% + 4px);
    width: calc(100% + 4px);
    margin-left: -2px;

    &.mob {
      max-width: calc(100% + 4px);
    }
  }

  .event-footer-social {
    margin-bottom: 2rem;
    display: flex;

    a:first-child {
      margin-right: 1rem;
    }

    a {
      width: 56px;
      height: 56px;
    }
  }

  .email-btn {
    margin-bottom: 3rem;
  }

  .made-by {
    font-size: 1.1rem;
  }
}

// GRADIENT TEXT
.gradient-text {
  background: linear-gradient(95.42deg, #F3499B 2.54%, #EA50E9 39.84%, #FAFF00 118.78%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.yellow-text {
  color: var(--yellow);
}

.pink-text {
  color: var(--pink);
}

.my-chip {
  padding: 1rem 2rem;
  border-radius: 100px;
  font-weight: var(--fweight);
  font-size: 2rem;
  text-align: center;
}
.my-chip-primary {

  &.my-chip-outline {
    border: var(--neon-pink) 1px solid;
    color: var(--neon-pink);
  }

  &.my-chip-filled {
    background: var(--neon-pink);
    color: black;
  }

}
.my-chip-secondary {

  &.my-chip-outline {
    border: var(--yellow) 1px solid;
    color: var(--yellow);
  }

  &.my-chip-filled {
    background: var(--yellow);
    color: black;
  }
}
.my-chip-tertiary {
  font-family: Stem;
  font-weight: bold;

  &.my-chip-outline {
    border: black 1px solid;
    color: black;
  }

  &.my-chip-filled {
    background: white;
    color: black;
  }
}
.schedule-card {
  padding: 2.5rem 2rem;
  border-radius: 15px;
  font-weight: 500;
  text-align: left;
  position: relative;

  & .blur {
    filter: blur(4px);
  }

  & .tba {
    position: absolute;
    font-size: 1.5rem;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > div:not(:last-child) {
    margin-bottom: 0.8rem;
  }

  & span {
    margin-left: 10px;
  }
}
.schedule-card-primary {
  border: var(--neon-pink) 1px solid;
  color: var(--neon-pink);
}
.schedule-card-secondary {
  border: var(--yellow) 1px solid;
  color: var(--yellow);
}
.schedule-card-tertiary {
  border: var(--lightBlue) 1px solid;
  color: var(--lightBlue);
}

.speaker-box-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }

  .name {
    font-weight: bold;
    font-size: 1.3rem;
    color: white;
    font-family: Stem;
    text-align: center;
    margin: 1.5rem 0 0.5rem;
    line-height: normal;
  }
  .sub {
    font-size: 1.3rem;
    font-weight: 500;
    text-align: center;
    color: var(--pink);
  }
}


/* SCROLLBAR */

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
*::-webkit-scrollbar-track {
  background-color: #000000;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #ec20f7;
}
