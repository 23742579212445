@media screen and (max-width: 1300px) {
  .volcano-wr {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .volcano-cnt .volcano {
    bottom: -20rem;
  }

  .event-s6 {
    .sponsors-ctn {
      & > div {
        img {
          height: 220px;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  /* header .col-6 {
    display: none;
  } */

  .volcano-cnt .bg img {
    height: 200vh;
  }
  .volcano-cnt {
    .volcano {
      bottom: -10rem;
      width: 150%;
      transform: translateX(-50%);
      margin-left: 50%;
    }
  }

  .what {
    padding: 0 3rem;
    flex-direction: column;
    align-items: center;

    .col-12 {
      padding: 0;
    }

    .col-sm-12:nth-last-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      margin-top: 5rem;
      max-width: 60%;
    }

    .col-md-8 {
      width: 100%;
      text-align: start;
    }

    h3 {
      max-inline-size: 100%;
    }
  }

  .community {
    margin-top: 4rem;

    .col-12 {
      padding: 0;
    }

    .category {
      padding: 0 3rem;
    }
  }

  .community .community-logos-cnt {
    margin-top: 10%;
    margin-bottom: 10%;
    width: 100%;

    .col {
      min-width: 50vw;
    }
  }

  .community h3 {
    padding: 0 3rem;
    text-align: end;
    margin: 0;
    max-inline-size: none;
  }

  .cta {
    padding: 4rem 3rem;
  }
  footer {
    img {
      margin-top: 0rem;
    }

    .col-sm-12.col-xl-6 {
      text-align: center;
      margin-bottom: 4rem;
    }

    .row:nth-last-child(1) {
      .social-cnt {
        text-align: end;
        a {
          margin: 0;
        }
      }
    }

    .contact-cnt {
      .mail-cnt {
        text-align: center;
      }

      .grad-btn-cnt {
        margin: 0 auto;
      }
    }
  }

  .event-s2 {
    h3 {
      text-align: center;
    }
  }

  .event-s7 {
    .event-s7-ethna-off-ctn {
      .event-s7-ethna-off-info {
        height: 70%;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .ethna .cta .custom-margin-top {
    margin-top: 15px!important;
  }

  .event-s6 {
  .sponsors-ctn {
    margin-top: 2rem;
    & > div {
        img {
          height: 180px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .event-s3 {
    h1 {
      font-size: 80px;
    }

    .event-s3-description {
      font-size: 1.4rem;
    }
  }
  .event-s8 {
    h1 {
      font-size: 80px;
    }
  }
}
@media screen and (max-width: 800px) {
  .event-s5 {
    .speakers-ctn {
      grid-template-columns: 33% 33% 33%;
    }
  }
  .event-s6 {
    margin-top: 4rem;
    .sponsors-ctn {
      grid-template-columns: 33% 33% 33%;
      & > div {
        max-width: min(100%, 300px);
        min-width: 140px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  footer {
    .desk {
      display: none;
    }
    .mob {
      display: inline;
    }

    .row {
      padding: 0 2rem;
    }
    img {
      max-width: 70%;
    }
    .social a{
      margin-bottom: 20px;
    }

    .logo-foot {
      max-width: 60%;
    }

    .txt-b {
      color: black;
      font-family: Instrument Sans;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.96875rem;
    }

    .contact-cnt .mail-cnt .mob {
      text-align: center;
      width: 100%;
      margin-top: 4rem;

      .grad-btn-cnt {
        margin: 0 auto;
      }
    }

    .row:nth-last-child(1) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-top: 0rem !important;
      padding-top: 2rem;

      .social-cnt {
        text-align: center;
        margin-bottom: 3rem;

        .social {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        a {
          margin: 0 1rem;

          svg {
            height: auto;
            width: 35px;
          }
        }
      }
    }

    .lava {
      min-width: 150%;
      margin-left: -1px;
    }
  }
  h3{
    font-size: 1.2rem!important;
  }
  .community .community-logos-cnt {
    margin-top: 30%;
  }

  header .row {
    justify-content: flex-end;
  }

  .what {
    img {
      margin-top: 3rem;
      width: 60%;
    }
  }

  .community {
    margin-top: 3rem;
  }

  .event-s1 {
    .event {
      .ethna {

        svg {
          width: 80%;
        }

        .event-cta {

          .apply-as-text {
          font-size: 1rem;
          margin-bottom: 2rem;
          }

          .grad-btn-cnt:first-child {
            margin-right: 0;
            margin-bottom: 2rem;
          }
        }

        .apply-ctn {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  .event-s3 {
    h1 {
      font-size: 50px;
    }

    .event-s3-description {
      padding-right: 2rem;
      padding-left: 2rem;
      font-size: 1.2rem;
    }
  }

  .event-s4 {
    .event-s4-row {
      flex-direction: column;
      .event-s4-col:first-child {
        margin-bottom: 5rem;
      }

      .event-s4-col {

        .event-s4-chip {
          width: 70%;
          min-width: 200px;
        }

        p {
          font-size: 1.5rem;
        }
      }
    }
  }

  .event-s5 {
    .speakers-ctn {
      grid-template-columns: 50% 50%;
      & > div {
        max-width: 300px;
        min-width: 140px;
      }
    }
  }

  .event-s6 {
    .sponsors-ctn {
      grid-template-columns: 50% 50%;
      padding-right: 1rem;
      padding-left: 1rem;
      & > div {
        max-width: min(100%, 300px);
      }
    }
  }

  .event-s8 {
    h1 {
      font-size: 50px;
    }

    .event-s8-info-ctn {
      flex-direction: column;
      align-items: center;
      margin-top: 6rem;
      & > div {
        width: 90%;
        max-width: 300px;
        &:not(:last-child) {
          margin-bottom: 4rem;
        }
        & > img {
          max-width: 100%;
          object-fit: contain;
          margin-bottom: 2rem;
        }
      }

    }
  }

  .event-footer {
    .event-footer-phrase {
      margin: 4rem auto 0;
    }

    .event-footer-logo {
      max-width: 100%;
    }

    .email-btn .grad-btn, .pitch-btn .grad-btn {
      width: 100%;
    }
  }

  .my-chip {
    font-size: 1.8rem;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  :root {
    --titles: 1.5rem;
    --btnFontSize: 1rem;
  }

  header {

    img{
      max-width: 35px;
    }

    .menu-btn-cnt button,
    .menu-btn-cnt a{
      font-size: var(--btnFontSize);
      padding: 0.5rem 1rem;
      line-height: 1.9;
      height: 1.8rem;
    }
  }

  .event-header {
    .menu-btn-cnt {
      a,button {
        height: 2.8rem;
      }
    }
  }

  .category {
    display: block;
  }

  .volcano-cnt{
    bottom: 0px;
    top: unset;
    height: 90lvh;
  }
  .volcano-cnt .volcano {
    bottom: -10rem;
    width: 200%;
    transform: translateX(-50%);
    margin-left: 50%;
  }

  .what {
    padding: 0 1.3rem;

    .col-md-8 {
      width: 100%;
      text-align: start;
    }
  }

  .what img {
    margin: 5rem 0;
    width: 55%;
  }

  .community {

    margin-top: 3rem;
    .row {
      margin: 0 !important;
    }

    .category {
      padding: 0 1rem;
    }

    h3 {
      padding: 0 1rem;
    }

    .community-logos-cnt p {
      font-size: 1rem;
    }
  }

  .community .community-logos-cnt .col img {
    height: 87px;
    width: 65px;
  }

  .cta {
    padding: 4rem 1rem;

    .row {
      margin: 0;
    }
  }

  header {
    .row {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }

  .friends .banner h1 {
    font-size: 3.375rem;
  }

  footer {
    .grad-btn-cnt a {
      display: flex;
      justify-content: center;
    }
    .grad-btn-cnt .grad-btn {
      width: 7rem;
    }
    .grad-btn-cnt .btn-5 {
      left: 3rem;
    }
    .grad-btn-cnt .btn-2 {
      right: 3rem;
    }
    .grad-btn-cnt .btn-4 {
      left: 1rem;
    }
    .grad-btn-cnt .btn-1 {
      right: 1rem;
    }

    .grad-btn-cnt:hover {
      .btn-1,
      .btn-2 {
        right: 6rem;
      }
      .btn-4,
      .btn-5 {
        left: 6rem;
      }
    }
  }

  .home .ethna {
    padding: 0px 1rem;

    svg {
      max-width: 85%;
      margin-top: 1rem;
    }
    .cta {
      padding: 2rem 1rem;
    }
  }

  .friends.event {
    .banner {
      h1 {
        font-size: 50px !important;
      }
    }
  }

  .event-page {
    .grad-btn-cnt .grad-btn {
      height: 2.8rem;
      box-sizing: border-box;
      padding: 0.5rem 1rem;
    }

    .grad-btn-cnt .btn-1 {
      right: -2rem;
    }
    .grad-btn-cnt .btn-2 {
      right: -1rem;
    }

    .grad-btn-cnt .btn-4 {
      left: -1rem;
    }

    .grad-btn-cnt .btn-5 {
      left: -2rem;
    }

    .grad-btn-cnt:hover {
      .btn-1,
      .btn-2 {
        right: 0rem;
      }
      .btn-4,
      .btn-5 {
        left: 0rem;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .event-s5 {
    .banner {
      h1 {
        font-size: 50px;
      }
    }
  }
  .event-s6 {
    .banner {
      h1 {
        font-size: 50px;
      }
    }
  }

  .event-s7.mobile {
    .event-s7-ethna-off-ctn {
      .event-s7-ethna-off-info {
        height: 67%;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  header {
    img {
      height: auto;
      max-width: 45px;
    }
  }

  footer .grad-btn-cnt .grad-btn {
    width: 50%;
  }
  footer .row:nth-last-child(1) {
    padding-left: 0;
    padding-right: 0;
  }

  .event-s5 {
    .speakers-ctn {
      grid-template-columns: 100%;
    }
  }

  .event-s6 {
    .sponsors-ctn {
      grid-template-columns: 100%;
    }
  }
}

@media screen and (max-width: 350px) {
  .volcano-cnt .volcano {
    width: 250%;
  }

  .community .community-logos-cnt p {
    font-size: 1.25rem;
  }

  .grad-btn-cnt .btn-1 {
    right: -2rem;
  }
  .grad-btn-cnt .btn-2 {
    right: -1rem;
  }

  .grad-btn-cnt .btn-4 {
    left: -1rem;
  }

  .grad-btn-cnt .btn-5 {
    left: -2rem;
  }
}

@media screen and (max-width: 1680px) {
  .what img {
    max-width: 300px;
  }
}

@media screen and (max-height: 800px) {
  .event-s1 .event {
    justify-content: end;
    padding-bottom: 2rem;
  }

  .event-s2 {
    margin-top: 8rem;
  }
}

@media screen and (max-height: 560px) {
  .event-s1 .event {
    .ethna h3{
      margin: 2rem auto;
    }
  }
}